import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import {useEffect, useState} from 'react';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Avatar,
  Button,
  Popover,
  Checkbox,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination, CircularProgress,
} from '@mui/material';
// components
import Label from '../components/label';
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead, UserListToolbar } from '../sections/@dashboard/user';
// mock
//import USERLIST from '../_mock/user';
import {da} from "date-fns/locale";
import {useNavigate} from "react-router-dom";
import ConfirmationDialog from "../components/amr/ConfirmationDialog";
import ShowBannedUsersCheckBox from "../components/amr/ShowBannedUsersCheckBox";
let USERLIST = [];
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'plan', label: 'Plan', alignRight: false },
  { id: 'register_at', label: 'Register at', alignRight: false },
  { id: 'total_created_lessons', label: 'Lessons Created', alignRight: false },
  { id: 'last_login_at', label: 'Last Login', alignRight: false },
  //{ id: 'status', label: 'Status', alignRight: false },
  { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  //console.log("Order by value: ", orderBy);
  //console.log("A & B: ", a, " ", b);
  if (orderBy === "plan") {
    const plan_b = b[orderBy];
    const plan_a = a[orderBy];
    let rank_b = 0;
    let rank_a = 0;
    if (plan_a === null)
      rank_a = 0;
    else {
      if (plan_a.plane_name.includes("trial")) {
        rank_a = 1;
      }
      else if (plan_a.plane_name.includes("Basic")) {
        rank_a = 2;
      }
      else {
        rank_a = 3;
      }
    }

    if (plan_b === null)
      rank_b = 0;
    else {
      if (plan_b.plane_name.includes("trial")) {
        rank_b = 1;
      }
      else if (plan_b.plane_name.includes("Basic")) {
        rank_b = 2;
      }
      else {
        rank_b = 3;
      }
    }

    if (rank_b < rank_a) {
      return -1;
    }
    else if (rank_b > rank_a) {
      return 1;
    }
    return 0;
  }
  else if (orderBy === "register_at") {
    const date1 = new Date(b[orderBy]);
    const date2 = new Date(a[orderBy]);
    if (date1 < date2) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  else {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.email.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function UserPage() {
  const [open, setOpen] = useState(null);

  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(100);

  const userToken = localStorage.getItem('userToken');

  const [loading_users, setLoading_users] = useState(false);
  const [current_selected_user, setCurrent_selected_user] = useState();
  const navigator = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    //console.log(filteredUsers);
    setLoading_users(true);
    listAllUsers().then(data => {
      //console.log(data);
      USERLIST = data;
      setfilteredUsers(data);
      setLoading_users(false);
      setIsChecked(false);
    }).catch(error => {
      console.error(error);
    });
  }, []);

  async function Real_List_All_users() {
    setLoading_users(true);
    listAllUsers().then(data => {
      //console.log(data);
      USERLIST = data;
      setfilteredUsers(data);
      setLoading_users(false);
      setIsChecked(false);
    }).catch(error => {
      console.error(error);
    });
  }

  async function Real_List_Banned_users() {
    setLoading_users(true);
    list_banned_users().then(data => {
      //console.log(data);
      USERLIST = data;
      setfilteredUsers(data);
      setLoading_users(false);
      setIsChecked(true);
    }).catch(error => {
      console.error(error);
    });
  }

  async function listAllUsers() {
    const token = "Bearer " + userToken;

    const response = await fetch('https://ailessonplan.com/core/api/list_all_users', {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    });

    const data = await response.json();

    return data;
  }

  async function list_banned_users() {
    const token = "Bearer " + userToken;

    const response = await fetch('https://ailessonplan.com/core/api/list_banned_users', {
      headers: {
        'Accept': 'application/json',
        'Authorization': token
      }
    });

    const data = await response.json();

    return data;
  }
  const handleOpenMenu = (event) => {
    setOpen(event.currentTarget);
    //console.log("Target: ", event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    setfilteredUsers(applySortFilter(USERLIST, getComparator(order, orderBy), filterName))
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = USERLIST.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const handleFilterByName = (event) => {
    //console.log("Name changed: ", event.target.value);
    setPage(0);
    setFilterName(event.target.value);
    setfilteredUsers(applySortFilter(USERLIST, getComparator(order, orderBy), filterName));
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - USERLIST.length) : 0;

  const [filteredUsers, setfilteredUsers] = useState(applySortFilter(USERLIST, getComparator(order, orderBy), filterName));

  const isNotFound = !filteredUsers.length && !!filterName;

  const [showConfirmation, setShowConfirmation] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const handleBanUser = () => {
    setShowConfirmation(true);
  };

  const handleConfirmation = async () => {
    setShowConfirmation(false);
    setIsFetching(true);

    try {
      const response = await fetch(
          'https://ailessonplan.com/core/api/ban_user?user_id=' + current_selected_user.id,
          {
            method: 'GET',
            headers: {
              'Authorization': 'Bearer ' + userToken,
            },
          }
      );

      // Handle the response as needed
      if (response.ok) {
        // Success
        navigator('/dashboard/user');
      } else {
        // Handle error response
      }
    } catch (error) {
      // Handle fetch error
    } finally {
      setIsFetching(false);
    }
  };

  return (
    loading_users ?
        (
            <>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
                <Stack>
                  <Typography>Loading Users....</Typography>
                  <CircularProgress sx={{marginLeft: '45px', marginTop: '30px'}}/>
                </Stack>
              </div>
            </>
        )
        :
        (
            <>
              <Helmet>
                <title> User | AI-LessonPlan </title>
              </Helmet>

              <Container>
                <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                  <Typography variant="h4" gutterBottom>
                    User
                  </Typography>
                  <Typography variant="h6" gutterBottom>
                    Total Users: {USERLIST.length}
                  </Typography>
                  {/*<Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />}>
                    New User
                  </Button>*/}
                </Stack>

                <Card>
                  <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} />
                  <div className={"p-10 m-10"}>
                    <ShowBannedUsersCheckBox onChecked={Real_List_Banned_users} onUnchecked={Real_List_All_users} check_status={isChecked}/>
                  </div>

                  <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                      <Table>
                        <UserListHead
                            order={order}
                            orderBy={orderBy}
                            headLabel={TABLE_HEAD}
                            rowCount={USERLIST.length}
                            numSelected={selected.length}
                            onRequestSort={handleRequestSort}
                            onSelectAllClick={handleSelectAllClick}
                        />
                        <TableBody>
                          {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const { id, name, email, plan, register_at, total_created_lessons, last_login_at, avatarUrl } = row;
                            const selectedUser = selected.indexOf(name) !== -1;

                            return (
                                <TableRow hover key={id} tabIndex={-1} role="checkbox" selected={selectedUser}>
                                  <TableCell padding="checkbox">
                                    <Checkbox checked={selectedUser} onChange={(event) => handleClick(event, name)} />
                                  </TableCell>

                                  <TableCell component="th" scope="row" padding="none">
                                    <Stack direction="row" alignItems="center" spacing={2}>
                                      <Avatar alt={name} src={avatarUrl} />
                                      <Typography variant="subtitle2" noWrap>
                                        {name}
                                      </Typography>
                                    </Stack>
                                  </TableCell>

                                  <TableCell align="left">{email}</TableCell>

                                  <TableCell align="left">{plan == null ? (<Label color={'error'}>{"Not started"}</Label>) : <Label color={'success'}>{plan.plane_name}</Label>}</TableCell>

                                  <TableCell align="left">{register_at}</TableCell>

                                  <TableCell align="left">{total_created_lessons}</TableCell>

                                  <TableCell align="left">{last_login_at}</TableCell>

                                  {/*<TableCell align="left">
                          <Label color={(status === 'banned' && 'error') || 'success'}>{sentenceCase(status)}</Label>
                        </TableCell>*/}

                                  <TableCell align="right">
                                    <IconButton size="large" color="inherit" onClick={event => {
                                      handleOpenMenu(event);
                                      setCurrent_selected_user(row);
                                    }}>
                                      <Iconify icon={'eva:more-vertical-fill'} />
                                    </IconButton>
                                  </TableCell>
                                </TableRow>
                            );
                          })}
                          {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                          )}
                        </TableBody>

                        {isNotFound && (
                            <TableBody>
                              <TableRow>
                                <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                  <Paper
                                      sx={{
                                        textAlign: 'center',
                                      }}
                                  >
                                    <Typography variant="h6" paragraph>
                                      Not found
                                    </Typography>

                                    <Typography variant="body2">
                                      No results found for &nbsp;
                                      <strong>&quot;{filterName}&quot;</strong>.
                                      <br /> Try checking for typos or using complete words.
                                    </Typography>
                                  </Paper>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                        )}
                      </Table>
                    </TableContainer>
                  </Scrollbar>

                  <TablePagination
                      rowsPerPageOptions={[100, 250, 500]}
                      component="div"
                      count={USERLIST.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </Card>
              </Container>

              <Popover
                  open={Boolean(open)}
                  anchorEl={open}
                  onClose={handleCloseMenu}
                  anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  PaperProps={{
                    sx: {
                      p: 1,
                      width: 140,
                      '& .MuiMenuItem-root': {
                        px: 1,
                        typography: 'body2',
                        borderRadius: 0.75,
                      },
                    },
                  }}
              >
                <MenuItem onClick={() => {
                  navigator('/dashboard/user_info', {state: {'client_id': current_selected_user.id, 'userToken' : userToken}});
                  //console.log("Yes Clicked");
                }}>
                  <Iconify icon={'eva:edit-fill'} sx={{ mr: 2 }} />
                  Edit
                </MenuItem>

                <MenuItem
                    sx={{ color: 'error.main' }}
                    onClick={handleBanUser}
                    disabled={isFetching}
                >
                  <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                  Ban
                </MenuItem>

                {showConfirmation && (
                    <ConfirmationDialog
                        title="Ban User"
                        message="Are you sure you want to ban this user?"
                        confirmText="Yes"
                        cancelText="No"
                        onConfirm={handleConfirmation}
                        onCancel={() => setShowConfirmation(false)}
                        showProgress={isFetching}
                    />
                )}
              </Popover>
            </>
        )
  );
}
