import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { Link, Stack, IconButton, InputAdornment, TextField, Checkbox } from '@mui/material';
import {Alert, LoadingButton} from '@mui/lab';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);


    const [EmailTextField, setEmailTextField] = useState('');
    const [PasswordTextField, setPasswordTextField] = useState('');

    const [EmptyEmail, setEmptyEmail] = useState(false);
    const [EmptyPassword, setEmptyPassword] = useState(false);

    const [WrongUserNameOrPassword, setWrongUserNameOrPassword] = useState(false);


    const [loginPressed, setLoginPressed] = useState(false);
    const handleClick = async () => {
        const email = EmailTextField.trim();
        const password = PasswordTextField.trim();
        let x = false;
        if (email.length === 0) {
            setEmptyEmail(true);
            x = true;
        }
        if (password.length === 0) {
            setEmptyPassword(true);
            x = true;
        }
        if (x) {
            return;
        }
        if (loginPressed)
            return;
        setLoginPressed(true);
      await fetch('https://ailessonplan.com/core/api/admin_login', {
          method: 'POST',
          headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
              'email': email,
              'password': password
          })
      })
          .then(response => response.json())
          .then(data => {
              if (data.token) {
                  // Save the token into the storage
                  localStorage.setItem('userToken', data.token);

                  // Save the user data into user object
                  const user = data.user;
                  navigate('/dashboard', {replace: true});
                  // ...
              } else {
                  console.log(data.message);
                  setWrongUserNameOrPassword(true);
                  setLoginPressed(false);
              }
          })
          .catch(error => {
              console.error('Error:', error);
              setLoginPressed(false);
          });
      //navigate('/dashboard', {replace: true});
  };

  return (
    <>
      <Stack spacing={3}>
          {
              WrongUserNameOrPassword ?
                  <Alert severity="error">Wrong Email or Password, please try again!</Alert>
                  :
                  (
                      <>
                      </>
                  )
          }
        <TextField name="email" label="Email address" error={EmptyEmail} helperText={"Please enter a valid email"} onChange={event => {
            setEmailTextField(event.target.value)
            if (event.target.value.length > 0)
                setEmptyEmail(false);
            else
                setEmptyEmail(true);
        }}/>

        <TextField
         error={EmptyPassword} helperText={"Please enter a valid password"}
         onChange={event => {
             setPasswordTextField(event.target.value);
             if (event.target.value.length > 0)
                 setEmptyPassword(false);
             else
                 setEmptyPassword(true);
         }}
          name="password"
          label="Password"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                  <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </Stack>

      <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
        {/*<Checkbox name="remember" label="Remember me" title={"Remember me"} />*/}
        {/*<Link variant="subtitle2" underline="hover">
          Forgot password?
        </Link>*/}
      </Stack>

      <LoadingButton fullWidth size="large" type="submit" variant="contained" onClick={handleClick} loading={loginPressed}>
        Login
      </LoadingButton>
    </>
  );
}
