import {useEffect, useState} from "react";
import {da} from "date-fns/locale";
import {CircularProgress, Stack, Typography} from "@mui/material";
import PaymentTable from "./PaymentList/PaymentTable";

function PaymentList() {

    const [payment_list, setPayment_list] = useState();

    const [loadingList, setLoadingList] = useState(true);

    const userToken = localStorage.getItem('userToken');

    async function fetchPayments() {
        setLoadingList(true);
        const token = 'Bearer ' + userToken;

        const response = await fetch('https://ailessonplan.com/core/api/payments', {
            headers: {
                Accept: 'application/json',
                Authorization: token,
            },
        });

        const data = await response.json();
        setPayment_list(data);
        setLoadingList(false);
    }

    useEffect(() => {
        fetchPayments();
    }, []);

    return(
        <>
            {
                loadingList ?
                    (
                        <>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
                                <Stack>
                                    <Typography>Loading Users....</Typography>
                                    <CircularProgress sx={{marginLeft: '45px', marginTop: '30px'}}/>
                                </Stack>
                            </div>
                        </>
                    )
                    :
                    (
                        <>
                            <Stack spacing={2} style={{ height: '100%' }}>
                                <Typography>
                                    Total Profits ${payment_list.total_profit}
                                </Typography>
                                <PaymentTable rows={payment_list} style={{ flex: 1 }}/>
                            </Stack>
                        </>
                    )
            }
        </>
    )
}

export default PaymentList;