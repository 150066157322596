import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    Select,
    MenuItem,
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Tabs,
    Tab,
    CircularProgress,
    Snackbar,
    TablePagination
} from '@mui/material';
import {Alert} from "@mui/lab";

const EmailTemplateEditor = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const user_id = queryParams.get('user_id');

    const [sentEmails, setSentEmails] = useState([]);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState(null);
    const [templateHTML, setTemplateHTML] = useState('');
    const [activeTab, setActiveTab] = useState(0);
    const [openDialog, setOpenDialog] = useState(false);
    const [loading, setLoading] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const userToken = localStorage.getItem('userToken');

    useEffect(() => {
        // Load sent emails
        const userToken = localStorage.getItem('userToken');
        const fetchSentEmails = async () => {
            try {
                const response = await axios.get(`https://ailessonplan.com/core/api/email_sent?user_id=${user_id}`, {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    }
                });
                setSentEmails(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchSentEmails();

        // Load email templates
        const fetchEmailTemplates = async () => {
            try {
                const response = await axios.get('https://ailessonplan.com/core/api/email_temps', {
                    headers: {
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${userToken}`
                    }
                });
                setEmailTemplates(response.data);
            } catch (error) {
                console.error(error);
            }
        };
        fetchEmailTemplates();
    }, [user_id]);

    const handleTemplateChange = (event) => {
        const templateId = event.target.value;
        const selectedTemplate = emailTemplates.find((template) => template.id === templateId);
        setSelectedTemplate(selectedTemplate);
        setTemplateHTML('');
        if (selectedTemplate) {
            // Fetch template HTML
            axios
                .get(`https://ailessonplan.com/core/api/get_email_content?id=${selectedTemplate.id}`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`
                    }
                })
                .then((response) => {
                    setTemplateHTML(response.data);
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    };

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleSendEmail = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleConfirmSend = () => {
        setLoading(true);
        setOpenDialog(false);

        // Send email API call
        const userToken = localStorage.getItem('userToken');
        axios
            .post(
                'https://ailessonplan.com/core/api/send_user_email',
                `email_id=${selectedTemplate.id}&user_id=${user_id}&content=${templateHTML}`,
                {
                    headers: {
                        'Accept': 'application/json',
                        'Content-Type': 'application/x-www-form-urlencoded',
                        'Authorization': `Bearer ${userToken}`
                    }
                }
            )
            .then((response) => {
                setLoading(false);
                setSnackbarOpen(true);
                setSnackbarMessage('Email sent successfully!');
                //setSentEmails((prevSentEmails) => [...prevSentEmails, response.data]);
            })
            .catch((error) => {
                setLoading(false);
                console.error(error);
                setSnackbarOpen(true);
                setSnackbarMessage('Failed to send email. Please try again.');
            });
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const paginatedEmails = sentEmails.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
        <div>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Template Name</TableCell>
                            <TableCell>Date Sent</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {paginatedEmails.map((email) => (
                            <TableRow key={email.id}>
                                <TableCell>{email.email_template.template_name}</TableCell>
                                <TableCell>{new Date(email.created_at).toLocaleDateString()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={sentEmails.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>

            <Typography variant="h6" component="div" sx={{ mt: 3 }}>
                Select Email Template
            </Typography>
            <Select value={selectedTemplate ? selectedTemplate.id : ''} onChange={handleTemplateChange} sx={{ mb: 2 }}>
                {emailTemplates.map((template) => (
                    <MenuItem key={template.id} value={template.id}>
                        {template.template_name}
                    </MenuItem>
                ))}
            </Select>

            {selectedTemplate && (
                <div>
                    <Typography variant="h6" component="div" sx={{ mt: 3 }}>
                        Email Template Editor
                    </Typography>
                    <Tabs value={activeTab} onChange={handleTabChange} sx={{ mt: 1 }}>
                        <Tab label="HTML View" />
                        <Tab label="Edit HTML" />
                    </Tabs>
                    <div hidden={activeTab !== 0}>
                        <div dangerouslySetInnerHTML={{ __html: templateHTML }} />
                    </div>
                    <div hidden={activeTab !== 1}>
            <textarea
                value={templateHTML}
                onChange={(event) => setTemplateHTML(event.target.value)}
                style={{ width: '100%', minHeight: '300px', padding: '8px' }}
            />
                    </div>

                    <Button variant="contained" color="primary" onClick={handleSendEmail} sx={{ mt: 3 }}>
                        Send Email
                    </Button>
                </div>
            )}

            <Dialog open={openDialog} onClose={handleCloseDialog}>
                <DialogTitle>Confirmation</DialogTitle>
                <DialogContent>
                    <Typography>Are you sure you want to send this email?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleConfirmSend} color="primary" autoFocus>
                        Send
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar open={snackbarOpen} autoHideDuration={3000} onClose={handleSnackbarClose}>
                <Alert onClose={handleSnackbarClose} severity="success">
                    {snackbarMessage}
                </Alert>
            </Snackbar>

            {loading && (
                <div style={{ display: 'flex', justifyContent: 'center', marginTop: '24px' }}>
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default EmailTemplateEditor;
