import {useEffect, useState} from 'react';
import {Outlet, useNavigate, useOutlet} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
//
import Header from './header';
import Nav from './nav';
import {CircularProgress, Stack, Typography} from "@mui/material";
import user from "../../_mock/user";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const StyledRoot = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const Main = styled('div')(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const outlet = useOutlet();

  const [userData, setUserData] = useState();
  const [loadingData, setLoadingData] = useState(true);
  const userToken = localStorage.getItem('userToken');
  const navigator = useNavigate();


  useEffect(() => {
      const requestOptions = {
          method: "GET",
          headers: {
              Accept: "application/json",
              Authorization: "Bearer " + userToken,
          },
      };
      fetch("https://ailessonplan.com/core/api/admin", requestOptions)
          .then((response) => response.json())
          .then((data) => {
              if (data.hasOwnProperty('message')) {
                  navigator('/login');
                  return;
              }
              setUserData(data);
              //console.log(userData);
              setLoadingData(false);
          })
          .catch((error) => {
              console.log(error);
              navigator('/login');
          });
  }, []);
  return (
    loadingData ?
        (
            <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '100vh' }}>
                    <Stack>
                        <Typography>Loading the Admin Page....</Typography>
                        <CircularProgress sx={{marginLeft: '60px', marginTop: '30px'}}/>
                    </Stack>
                </div>
            </>
        )
        :
        (
            <StyledRoot>
              <Header onOpenNav={() => setOpen(true)} />

              <Nav openNav={open} onCloseNav={() => setOpen(false)} userData={userData} />

              <Main>
                <Outlet />
              </Main>
            </StyledRoot>
        )
  );
}
