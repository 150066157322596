import { useEffect, useState } from 'react';
import {convertFromRaw, Editor, EditorState, convertFromHTML, ContentState} from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import {useParams} from "react-router-dom";

const moduleNamesMap = {
    "1": "Textbook AI Lesson",
    "2": "Custom AI Lesson",
    "3": "Blooms tax",
    "4": "5e",
    "5": "Madeline Hunter",
    "6": "4a",
    "7": "Basic Lesson",
    "8": "Five-Step",
    "9": "Essay Writing",
    "10": "Lesson Plan Quiz",
    "11": "Text Book Quiz",
    "12": "Assignment Creator",
    "13": "FreeTemplate_1",
};

export default function LessonViewPage() {
    const [lessonData, setLessonData] = useState(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const userToken = localStorage.getItem('userToken');
    const { lessonId } = useParams();

    useEffect(() => {
        console.log(lessonId);
        const fetchLessonData = async () => {
            try {
                const response = await fetch('https://ailessonplan.com/core/api/admin_lesson_detail?id=' + lessonId, {
                    headers: {
                        Accept: 'application/json',
                        Authorization: 'Bearer ' + userToken,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const lessonData = await response.json();
                //console.log(lessonData);
                setLessonData(lessonData);

                // Convert the JSON string to draft-js content state
                if (lessonData.lesson) {
                    const contentState = convertFromHTML(lessonData.lesson);
                    const content = ContentState.createFromBlockArray(
                        contentState.contentBlocks,
                        contentState.entityMap
                    );
                    setEditorState(EditorState.createWithContent(content));
                }
            } catch (error) {
                console.error('Error fetching lesson data:', error);
            }
        };

        fetchLessonData();
    }, []);

    return (
        <div>
            {lessonData && (
                <div>
                    <h1 style={{ fontWeight: 'bold' }}>{lessonData.title}</h1>
                    <p>Module: {moduleNamesMap[lessonData.module]}</p>
                    <div>
                        <h2>Parameters:</h2>
                        <ul>
                            {Object.entries(JSON.parse(lessonData.params)).map(([key, value]) => (
                                <li key={key}>
                                    <strong>{key}:</strong> {key === "module" ? moduleNamesMap[value] : value}
                                </li>
                            ))}
                        </ul>
                    </div>
                    <div>
                        <h2>Lesson Data:</h2>
                        <div>
                            <Editor readOnly editorState={editorState} onChange={() => {}}/>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}
