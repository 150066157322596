import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, IconButton, Button } from '@mui/material';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewModuleIcon from '@mui/icons-material/ViewModule';

function ConfirmDialog({ open, onClose, onConfirm, module }) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete Module</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    Are you sure you want to delete the module "{module.title}"?
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={() => onConfirm(module.id)} color="secondary">
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
}


export default function ModulesPage() {
    const [modules, setModules] = useState([]);
    const navigate = useNavigate();
    const userToken = localStorage.getItem('userToken');
    const [openDialog, setOpenDialog] = useState(false);
    const [selectedModule, setSelectedModule] = useState(null);

    function renderTable() {
        return (
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Title</TableCell>
                            <TableCell>Description</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {modules.map((module) => (
                            <TableRow key={module.id}>
                                <TableCell>{module.title}</TableCell>
                                <TableCell>{module.description}</TableCell>
{/*                                <TableCell>
                                    <img src={`${module.icon}`} alt={module.title}
                                         style={{width: '50px'}}/>
                                </TableCell>*/}
                                {/*<TableCell>
                                    {module.icon ? (
                                        <img src={`${module.icon}`} alt={module.title} style={{ width: '50px' }} />
                                    ) : (
                                        <IconButton>
                                            <ViewModuleIcon />  Render the default MUI icon
                                        </IconButton>
                                    )}
                                </TableCell>*/}
                                <TableCell>{module.category}</TableCell>
                                <TableCell>
                                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                                        <IconButton onClick={() => navigate(`/dashboard/module/${module.id}`)}>
                                            <EditIcon />
                                        </IconButton>
                                        <IconButton onClick={() => handleDeleteClick(module)}>
                                            <DeleteIcon />
                                        </IconButton>
                                    </div>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }

    const handleDeleteClick = (module) => {
        setSelectedModule(module);
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
        setSelectedModule(null);
    };

    const handleConfirmDelete = (moduleId) => {
        const userToken = localStorage.getItem('userToken');
        fetch(`https://ailessonplan.com/core/api/ai-modular/${moduleId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        })
            .then(() => {
                setModules(modules.filter(module => module.id !== moduleId));
                handleCloseDialog();
            })
            .catch(error => console.error('Error:', error));
    };
    function deleteModule(moduleId) {
        const userToken = localStorage.getItem('userToken');

        fetch(`https://ailessonplan.com/core/api/ai-modular/${moduleId}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${userToken}`
            }
        })
            .then(response => {
                if (response.ok) {
                    // Remove the deleted module from the state
                    setModules(modules.filter(module => module.id !== moduleId));
                } else {
                    // Handle errors
                    console.error('Error deleting module:', response);
                }
            })
            .catch(error => console.error('Error:', error));
    }

    useEffect(() => {
        fetch('https://ailessonplan.com/core/api/ai-modulars', {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${userToken}`
            }
        })
            .then(response => response.json())
            .then(data => setModules(data))
            .catch(error => console.error('Error:', error));
    }, []);

    return (
        <>
            <div>
                <Button variant="contained" color="primary" onClick={() => navigate('/dashboard/create-module')}>
                    Add New Module
                </Button>
                {renderTable()}
                {selectedModule && (
                    <ConfirmDialog
                        open={openDialog}
                        onClose={handleCloseDialog}
                        onConfirm={handleConfirmDelete}
                        module={selectedModule}
                    />
                )}
            </div>
        </>
    );
}

