import React, { useState, useEffect } from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import {
    TextField,
    TextareaAutosize,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Select,
    MenuItem,
    InputLabel,
    FormControl,
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import DeleteIcon from '@mui/icons-material/Delete';

function EditModulePage() {
    const { moduleId } = useParams();
    const [moduleData, setModuleData] = useState({});
    const [fields, setFields] = useState([]);
    const [title, setTitle] = useState('');
    const [category, setCategory] = useState('');
    const [description, setDescription] = useState('');
    const [prompt, setPrompt] = useState('');
    const [icon, setIcon] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        // Fetch module data based on moduleId and update state
        const userToken = localStorage.getItem('userToken');
        fetch(`https://ailessonplan.com/core/api/ai-modular/${moduleId}`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Accept': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                setModuleData(data);
                setTitle(data.title);
                setCategory(data.category);
                setDescription(data.description);
                setPrompt(data.prompt);
                setFields(JSON.parse(data.fields));
            })
            .catch(error => console.error('Error:', error));
    }, [moduleId]);

    const handleIconChange = (event) => {
        setIcon(event.target.files[0]);
    };

    const handleFieldChange = (index, key, value) => {
        const newFields = fields.map((field, i) => {
            if (i === index) {
                return { ...field, [key]: value };
            }
            return field;
        });
        setFields(newFields);
    };

    const handleOptionsChange = (index, value) => {
        const options = value.split(',').map(opt => opt.trim());
        handleFieldChange(index, 'options', options);
    };

    const addField = () => {
        setFields([...fields, { label: '', type: 'textfield', holder: '', options: [] }]);
    };

    const removeField = (index) => {
        setFields(fields.filter((_, i) => i !== index));
    };

    const handleSubmit = () => {
        const userToken = localStorage.getItem('userToken');
        const formData = new FormData();
        formData.append('title', title);
        formData.append('description', description);
        formData.append('prompt', prompt);
        formData.append('category', category);
        formData.append('fields', JSON.stringify(fields));
        if (icon) {
            formData.append('icon', icon);
        }

        fetch(`https://ailessonplan.com/core/api/ai-modular/update/${moduleId}`, {
            method: 'POST',
            headers: {
                'Authorization': `Bearer ${userToken}`,
                'Accept': 'application/json',
            },
            body: formData,
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data);
                // Redirect to the module details page or any other appropriate page
                navigate('/dashboard/modules');
            })
            .catch(error => console.error('Error:', error));
    };

    return (
        <div>
            <label>Icon: </label>
            <input type="file" onChange={handleIconChange} />
            <TextField label="Title" value={title} onChange={(e) => setTitle(e.target.value)} fullWidth margin="normal" />
            <TextField label="Category" value={category} onChange={(e) => setCategory(e.target.value)} fullWidth margin="normal" />
            <TextareaAutosize minRows={3} placeholder="Description" value={description} onChange={(e) => setDescription(e.target.value)} style={{ width: '100%', marginTop: '20px' }} />
            <TextareaAutosize minRows={3} placeholder="Prompt" value={prompt} onChange={(e) => setPrompt(e.target.value)} style={{ width: '100%', marginTop: '20px' }} />

            <TableContainer component={Paper} style={{ marginTop: '20px' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Label</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Holder</TableCell>
                            <TableCell>Options (for Dropdown)</TableCell>
                            <TableCell>
                                <IconButton onClick={addField}>
                                    <AddCircleIcon />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {fields.map((field, index) => (
                            <TableRow key={index}>
                                <TableCell>
                                    <TextField value={field.label} onChange={(e) => handleFieldChange(index, 'label', e.target.value)} />
                                </TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <InputLabel>Type</InputLabel>
                                        <Select value={field.type} onChange={(e) => handleFieldChange(index, 'type', e.target.value)}>
                                            <MenuItem value="textfield">Text Field</MenuItem>
                                            <MenuItem value="textarea">Text Area</MenuItem>
                                            <MenuItem value="dropdown">Dropdown</MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField value={field.holder} onChange={(e) => handleFieldChange(index, 'holder', e.target.value)} />
                                </TableCell>
                                <TableCell>
                                    {field.type === 'dropdown' && (
                                        <TextareaAutosize
                                            minRows={2}
                                            placeholder="Enter options, separated by commas"
                                            value={field.options.join(', ')}
                                            onChange={(e) => handleOptionsChange(index, e.target.value)}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => removeField(index)}>
                                        <DeleteIcon />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Button variant="contained" color="primary" onClick={handleSubmit} style={{ marginTop: '20px' }}>
                Submit
            </Button>
        </div>
    );
}

export default EditModulePage;