import {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails, AccordionSummary, Button,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    Stack,
    TextField,
    Typography
} from "@mui/material";
import {useLocation, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet-async";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import UserLessonsTable from "./UserLessonsTable";

function UserInfo() {
    const [userInfo, setUserInfo] = useState({});
    const {client_id, userToken} = useLocation().state;
    const [loading_info, setLoading_info] = useState(true);
    const navigator = useNavigate();

    useEffect(() => {
        //console.log("ID: ", client_id, " token: ", userToken);
        setLoading_info(true);
        const fetchData = async () => {
            const response = await fetch('https://ailessonplan.com/core/api/user_info?id=' + client_id, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + userToken
                }
            });
            const data = await response.json();
            setUserInfo(data);
            setLoading_info(false);
        };
        fetchData();
    }, []);
    return(
        loading_info ?
            (
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', minHeight: '50vh' }}>
                        <Stack>
                            <Typography>Loading Users....</Typography>
                            <CircularProgress sx={{marginLeft: '45px', marginTop: '30px'}}/>
                        </Stack>
                    </div>
                </>
            )
            :
            (
                <>
                    <Helmet>
                        <title> User | AI-LessonPlan </title>
                    </Helmet>
                    <div>
                        <Stack spacing={2}>
                            <Card variant="outlined">
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Typography variant="h6" fontWeight="bold">User Basic Info</Typography>
                                        <Divider/>
                                        <Stack spacing={1}>
                                            <TextField label={"Name"} value={userInfo.user.name}></TextField>
                                            <TextField label={"Email"} value={userInfo.user.email}></TextField>
                                            <TextField label={"Register at"} value={userInfo.user.register_at}></TextField>
                                            <Button variant="contained" onClick={() => {
                                                navigator(`/dashboard/emails?user_id=${userInfo.user.id}`);
                                            }}>Email Area</Button>
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                            <Card variant="outlined">
                                <CardContent>
                                    <Stack spacing={2}>
                                        <Typography variant="h6" fontWeight="bold">User Plan Info</Typography>
                                        <Divider/>
                                        <Stack spacing={2}>
                                            {
                                                userInfo.plan === null ?
                                                    (
                                                        <Typography>
                                                            User did not active his plan yet
                                                        </Typography>
                                                    )
                                                    :
                                                    (
                                                        <>
                                                            <TextField label={"Plan Name"} value={userInfo.plan.plane_name}></TextField>
                                                            <TextField label={"Lessons/Month"} value={userInfo.plan.total_credit}></TextField>
                                                            <TextField label={"Lessons Created"} value={userInfo.plan.credit_used}></TextField>
                                                            <TextField label={"Auto-Renew"} value={userInfo.plan.renew === 1 ? "Enabled" : "Disabled"}></TextField>
                                                            <TextField label={"Register at"} value={userInfo.plan.register_at}></TextField>
                                                            <TextField label={"End at"} value={userInfo.plan.end_at}></TextField>
                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography variant="h6" fontWeight="bold">Payment Detail</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    {
                                                                        !userInfo.plan.hasOwnProperty("payment_detail") ?
                                                                            (
                                                                                <>
                                                                                    <Typography>
                                                                                        No Payment Detail Available
                                                                                    </Typography>
                                                                                </>
                                                                            )
                                                                            :
                                                                            (
                                                                                <Stack spacing={2}>
                                                                                    <TextField label={"Payment Stripe ID"} value={userInfo.plan.payment_detail.payment_id}></TextField>
                                                                                    <TextField label={"Plan"} value={userInfo.plan.payment_detail.plan}></TextField>
                                                                                    <TextField label={"Duration"} value={userInfo.plan.payment_detail.duration}></TextField>
                                                                                    <TextField label={"Amount"} value={userInfo.plan.payment_detail.amount}></TextField>
                                                                                    <TextField label={"Sub ID"} value={userInfo.plan.payment_detail.sub_id}></TextField>
                                                                                </Stack>
                                                                            )
                                                                    }
                                                                </AccordionDetails>
                                                            </Accordion>

                                                            <Accordion>
                                                                <AccordionSummary
                                                                    expandIcon={<ExpandMoreIcon />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography variant="h6" fontWeight="bold">Lessons Created (Total: {userInfo.total_lessons})</Typography>
                                                                </AccordionSummary>
                                                                <AccordionDetails>
                                                                    <UserLessonsTable rows={userInfo.lessons}/>
                                                                </AccordionDetails>
                                                            </Accordion>
                                                        </>
                                                    )
                                            }
                                        </Stack>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Stack>
                    </div>
                </>
            )
    );
}

export default UserInfo;