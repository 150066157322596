import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, CircularProgress } from '@mui/material';

const ConfirmationDialog = ({
                                title,
                                message,
                                confirmText,
                                cancelText,
                                onConfirm,
                                onCancel,
                                showProgress,
                            }) => {
    return (
        <Dialog open={true} onClose={onCancel}>
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{message}</DialogContent>
            <DialogActions>
                {showProgress ? (
                    <CircularProgress />
                ) : (
                    <>
                        <Button onClick={onCancel}>{cancelText}</Button>
                        <Button onClick={onConfirm} color="primary" autoFocus>
                            {confirmText}
                        </Button>
                    </>
                )}
            </DialogActions>
        </Dialog>
    );
};

ConfirmationDialog.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    confirmText: PropTypes.string.isRequired,
    cancelText: PropTypes.string.isRequired,
    onConfirm: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showProgress: PropTypes.bool.isRequired,
};

export default ConfirmationDialog;
