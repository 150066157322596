// src/components/LessonsPage.js

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Typography,
    CircularProgress,
    Pagination,
    Link, Button
} from '@mui/material';
import {useNavigate} from "react-router-dom";

const moduleNamesMap = {
    "1": "Textbook AI Lesson",
    "2": "Custom AI Lesson",
    "3": "Blooms tax",
    "4": "5e",
    "5": "Madeline Hunter",
    "6": "4a",
    "7": "Basic Lesson",
    "8": "Five-Step",
    "9": "Essay Writing",
    "10": "Lesson Plan Quiz",
    "11": "Text Book Quiz",
    "12": "Assignment Creator",
    "13": "FreeTemplate_1",
};

const LessonsPage = () => {

    const [lessons, setLessons] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [loading, setLoading] = useState(true);
    const userToken = localStorage.getItem('userToken');
    const navigator = useNavigate();

    const token = 'Bearer ' + userToken;
    const apiEndpoint = 'https://ailessonplan.com/core/api/admin_list_all_lessons';

    useEffect(() => {
        fetchLessons(currentPage);
    }, [currentPage]);

    const fetchLessons = (page) => {
        setLoading(true);
        axios
            .get(apiEndpoint, {
                headers: {
                    Authorization: token,
                },
                params: {
                    page,
                },
            })
            .then((response) => {
                setLessons(response.data.data);
                setCurrentPage(response.data.current_page);
                setTotalPages(Math.ceil(response.data.total / response.data.per_page));
                setLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching lessons:', error);
                setLoading(false);
            });
    };

    const handlePageChange = (event, newPage) => {
        setCurrentPage(newPage);
    };

    if (loading) {
        return <CircularProgress />;
    }

    return (
        <div>
            <Typography variant="h4" gutterBottom>
                Lessons List
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>User</TableCell>
                            <TableCell>Title</TableCell>
                            <TableCell>Module</TableCell>
                            <TableCell>Token Used</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {lessons.map((lesson) => (
                            <TableRow key={lesson.id}>
                                <TableCell>{lesson.user_plan.user.email}</TableCell>
                                <TableCell>{lesson.title}</TableCell>
                                <TableCell>{moduleNamesMap[lesson.module]}</TableCell>
                                <TableCell>{lesson.token_used}</TableCell>
                                <TableCell>
                                    <Button onClick={() => {
                                        navigator(`/dashboard/lesson/${lesson.id}`)
                                    }}>
                                        Detail
                                    </Button>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Pagination
                count={totalPages}
                page={currentPage}
                onChange={handlePageChange}
                color="primary"
            />
        </div>
    );
};

export default LessonsPage;