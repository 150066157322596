import React, { useEffect, useState } from 'react';
import { Container, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, Button, ButtonGroup } from '@mui/material';
import axios from 'axios';

const FeedbackPage = () => {
    const [feedbackData, setFeedbackData] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const userToken = localStorage.getItem('userToken');

    useEffect(() => {
        // Fetch feedback data
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://ailessonplan.com/core/api/admin_feedback?page=${currentPage}`, {
                    headers: {
                        Authorization: `Bearer ${userToken}`,
                    },
                });
                setFeedbackData(response.data.data);
                setTotalPages(response.data.last_page);
            } catch (error) {
                console.error('Error fetching feedback data:', error);
            }
        };

        fetchData();
    }, [currentPage, userToken]);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const formatDateTime = (dateTimeString) => {
        const date = new Date(dateTimeString);
        const yyyy = date.getFullYear();
        const MM = String(date.getMonth() + 1).padStart(2, '0');
        const dd = String(date.getDate()).padStart(2, '0');
        const HH = String(date.getHours()).padStart(2, '0');
        const mm = String(date.getMinutes()).padStart(2, '0');
        return `${yyyy}-${MM}-${dd} ${HH}:${mm}`;
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Feedback
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Quality of Lesson</TableCell>
                            <TableCell>Expectations</TableCell>
                            <TableCell>Comment</TableCell>
                            <TableCell>Created At</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {feedbackData.map((feedback) => (
                            <TableRow key={feedback.id}>
                                <TableCell>{feedback.user.name}</TableCell>
                                <TableCell>{feedback.user.email}</TableCell>
                                <TableCell>{feedback.quality_of_lesson}</TableCell>
                                <TableCell>{feedback.expectations}</TableCell>
                                <TableCell>{feedback.comment}</TableCell>
                                <TableCell>{formatDateTime(feedback.created_at)}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <ButtonGroup sx={{ marginTop: 2 }} color="primary">
                {Array.from({ length: totalPages }, (_, i) => (
                    <Button
                        key={i + 1}
                        onClick={() => handlePageChange(i + 1)}
                        variant={currentPage === i + 1 ? 'contained' : 'outlined'}
                    >
                        {i + 1}
                    </Button>
                ))}
            </ButtonGroup>
        </Container>
    );
};

export default FeedbackPage;
