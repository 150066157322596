import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import UserInfo from "./pages/UserDetail/UserInfo";
import PaymentList from "./pages/PaymentList";
import {LessonsModule} from "./pages/LessonsModule";
import EmailTemplateEditor from "./pages/Emails/EmailTemplateEditor";
import LessonViewPage from "./pages/UserDetail/LessonViewPage";
import LessonsList from "./pages/UserDetail/LessonsList";
import ModulesPage from "./pages/Modules/ModulesPage";
import CreateModulePage from "./pages/Modules/CreateModulePage";
import EditModulePage from "./pages/Modules/EditModulePage";
import FeedbackPage from "./pages/Feedback/FeedbackPage";

// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: 'app', element: <DashboardAppPage /> },
        { path: 'user', element: <UserPage /> },
        { path: 'products', element: <ProductsPage /> },
        { path: 'modules', element: <ModulesPage /> },
        { path: 'user_info', element: <UserInfo /> },
        { path: 'payments', element: <PaymentList /> },
        { path: 'lessons-modules', element: <LessonsModule /> },
        { path: 'emails', element: <EmailTemplateEditor /> },
        { path: 'lesson/:lessonId', element: <LessonViewPage /> },
        { path: 'list_lessons', element: <LessonsList /> },
        {path: 'create-module', element: <CreateModulePage />},
        {path: 'module/:moduleId', element: <EditModulePage/> },
        {path: 'feedback', element: <FeedbackPage/> }
      ],
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/app" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/404" replace />,
    },
  ]);

  return routes;
}
