import React, { useState } from 'react';
import Checkbox from '@mui/material/Checkbox';
import {FormControlLabel} from "@mui/material";

function ShowBannedUsersCheckBox({ onChecked, onUnchecked, check_status}) {
    const [isChecked, setIsChecked] = useState(check_status);

    const handleChecked = () => {
        setIsChecked(true);
        onChecked();
        console.log("Checked");
    };

    const handleUnchecked = () => {
        setIsChecked(false);
        onUnchecked();
        console.log("unchecked");
    };

    return (
        <FormControlLabel label={"Show Banned users"} control={
            <Checkbox

                checked={isChecked}
                onChange={(e) => (e.target.checked ? handleChecked() : handleUnchecked())}
            />
        }
        />
    );
}

export default ShowBannedUsersCheckBox;
