import { Helmet } from 'react-helmet-async';
import axios from 'axios';
import {formatDistanceToNow, parseISO} from 'date-fns';
// @mui
import { useTheme } from '@mui/material/styles';
import {Grid, Container, Typography, CircularProgress} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import {
  AppTasks,
  AppNewsUpdate,
  AppOrderTimeline,
  AppCurrentVisits,
  AppWebsiteVisits,
  AppTrafficBySite,
  AppWidgetSummary,
  AppCurrentSubject,
  AppConversionRates,
} from '../sections/@dashboard/app';
import {useEffect, useState} from "react";

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
    const [app_status, setAppStatus] = useState(null);
    const userToken = localStorage.getItem('userToken');
    const [newsData, setNewsData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            const response = await fetch('https://ailessonplan.com/core/api/status', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': 'Bearer ' + userToken,
                },
            });
            const data = await response.json();
            setAppStatus(data);
        };
        fetchData();

        const fetchData2 = async () => {
            try {
                const response = await axios.get(
                    'https://ailessonplan.com/core/api/admin_logs',
                    {
                        headers: {
                            Authorization: 'Bearer ' + userToken,
                        },
                    }
                );
                setNewsData(response.data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData2();
    }, []);

    // Function to convert date to human-readable format
    const timeAgo = (date) => {
        const parsedDate = parseISO(date); // Convert the date string to a valid Date object
        return formatDistanceToNow(parsedDate, { addSuffix: true });
    };

    return (
        <>
            <Helmet>
                <title> Dashboard | AI-LessonPlan </title>
            </Helmet>

            <Container maxWidth="xl">
                <Typography variant="h4" sx={{ mb: 5 }}>
                    Hi, Welcome back
                </Typography>

                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Total Sales profit" total={app_status == null ? -1 : app_status.profits} icon={'ant-design:android-filled'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="New Users" total={app_status == null ? -1 : app_status.total_users} color="info" icon={'ant-design:apple-filled'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="Item Orders" total={app_status == null ? -1 : app_status.total_payments} color="warning" icon={'ant-design:windows-filled'} />
                    </Grid>

                    <Grid item xs={12} sm={6} md={3}>
                        <AppWidgetSummary title="All Lessons Created" total={app_status == null ? -1 : app_status.tickets} color="error" icon={'ant-design:bug-filled'} />
                    </Grid>

                    {/*<Grid item xs={12} md={6} lg={8}>
                        <AppWebsiteVisits
                            title="Website Visits"
                            subheader="(+43%) than last year"
                            chartLabels={[
                                '01/01/2003',
                                '02/01/2003',
                                '03/01/2003',
                                '04/01/2003',
                                '05/01/2003',
                                '06/01/2003',
                                '07/01/2003',
                                '08/01/2003',
                                '09/01/2003',
                                '10/01/2003',
                                '11/01/2003',
                            ]}
                            chartData={[
                                {
                                    name: 'Team A',
                                    type: 'column',
                                    fill: 'solid',
                                    data: [23, 11, 22, 27, 13, 22, 37, 21, 44, 22, 30],
                                },
                                {
                                    name: 'Team B',
                                    type: 'area',
                                    fill: 'gradient',
                                    data: [44, 55, 41, 67, 22, 43, 21, 41, 56, 27, 43],
                                },
                                {
                                    name: 'Team C',
                                    type: 'line',
                                    fill: 'solid',
                                    data: [30, 25, 36, 30, 45, 35, 64, 52, 59, 36, 39],
                                },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentVisits
                            title="Current Visits"
                            chartData={[
                                { label: 'America', value: 4344 },
                                { label: 'Asia', value: 5435 },
                                { label: 'Europe', value: 1443 },
                                { label: 'Africa', value: 4443 },
                            ]}
                            chartColors={[
                                theme.palette.primary.main,
                                theme.palette.info.main,
                                theme.palette.warning.main,
                                theme.palette.error.main,
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={8}>
                        <AppConversionRates
                            title="Conversion Rates"
                            subheader="(+43%) than last year"
                            chartData={[
                                { label: 'Italy', value: 400 },
                                { label: 'Japan', value: 430 },
                                { label: 'China', value: 448 },
                                { label: 'Canada', value: 470 },
                                { label: 'France', value: 540 },
                                { label: 'Germany', value: 580 },
                                { label: 'South Korea', value: 690 },
                                { label: 'Netherlands', value: 1100 },
                                { label: 'United States', value: 1200 },
                                { label: 'United Kingdom', value: 1380 },
                            ]}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <AppCurrentSubject
                            title="Current Subject"
                            chartLabels={['English', 'History', 'Physics', 'Geography', 'Chinese', 'Math']}
                            chartData={[
                                { name: 'Series 1', data: [80, 50, 30, 40, 100, 20] },
                                { name: 'Series 2', data: [20, 30, 40, 80, 20, 80] },
                                { name: 'Series 3', data: [44, 76, 78, 13, 43, 10] },
                            ]}
                            chartColors={[...Array(6)].map(() => theme.palette.text.secondary)}
                        />
                    </Grid>*/}

                    <Grid container>
                        <Grid item xs={12} md={6} lg={8}>
                            <AppNewsUpdate
                                title="News Update"
                                list={newsData.map((item) => ({
                                    id: item.id,
                                    title: item.title,
                                    description: item.sub_title,
                                    image: item.image || '/assets/images/covers/cover_1.jpg', // Provide a default image URL if it's not provided in the API response
                                    postedAt: item.created_at,
                                }))}
                            />
                        </Grid>
                    </Grid>

                    {/*<Grid item xs={12} md={6} lg={4}>
                        <AppOrderTimeline
                            title="Order Timeline"
                            list={[...Array(5)].map((_, index) => ({
                                id: faker.datatype.uuid(),
                                title: [
                                    '1983, orders, $4220',
                                    '12 Invoices have been paid',
                                    'Order #37745 from September',
                                    'New order placed #XF-2356',
                                    'New order placed #XF-2346',
                                ][index],
                                type: `order${index + 1}`,
                                time: faker.date.past(),
                            }))}
                        />
                    </Grid>*/}

                    <Grid item xs={12} md={6} lg={4}>
                        <AppTrafficBySite
                            title="App Usage"
                            list={[
                                {
                                    name: 'Open AI Tokens Used',
                                    value: app_status == null ? -1 : app_status.tokens_used,
                                    icon: <Iconify icon={'ic:baseline-generating-tokens'} color="#1877F2" width={32} />,
                                },
/*                                {
                                    name: 'Google',
                                    value: 341212,
                                    icon: <Iconify icon={'eva:google-fill'} color="#DF3E30" width={32} />,
                                },
                                {
                                    name: 'Linkedin',
                                    value: 411213,
                                    icon: <Iconify icon={'eva:linkedin-fill'} color="#006097" width={32} />,
                                },
                                {
                                    name: 'Twitter',
                                    value: 443232,
                                    icon: <Iconify icon={'eva:twitter-fill'} color="#1C9CEA" width={32} />,
                                },*/
                            ]}
                        />
                    </Grid>

                    {/*<Grid item xs={12} md={6} lg={8}>
                        <AppTasks
                            title="Tasks"
                            list={[
                                { id: '1', label: 'Create FireStone Logo' },
                                { id: '2', label: 'Add SCSS and JS files if required' },
                                { id: '3', label: 'Stakeholder Meeting' },
                                { id: '4', label: 'Scoping & Estimations' },
                                { id: '5', label: 'Sprint Showcase' },
                            ]}
                        />
                    </Grid>*/}
                </Grid>
            </Container>
        </>
  );
}
